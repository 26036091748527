// Generated by Framer (bdd6aa1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["kRSkekv53"];

const variantClassNames = {kRSkekv53: "framer-v-q2bry4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, hi9P_amFC: title ?? props.hi9P_amFC ?? "S"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, hi9P_amFC, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "kRSkekv53", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-4ISQ7", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-q2bry4", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"kRSkekv53"} ref={ref} style={{backgroundColor: "rgba(133, 138, 142, 0.24)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0JlYXVzaXRlIENsYXNzaWMgV2ViIENsZWFy", "--framer-font-family": "\"Beausite Classic Web Clear\", sans-serif", "--framer-font-size": "10px", "--framer-line-height": "12px", "--framer-text-color": "var(--extracted-r6o4lv)"}}>S</motion.p></React.Fragment>} className={"framer-163a0ji"} data-framer-name={"Letter"} fonts={["CUSTOM;Beausite Classic Web Clear"]} layoutDependency={layoutDependency} layoutId={"ZAuGAD5na"} style={{"--extracted-r6o4lv": "var(--token-d96388da-2a42-4779-95b0-4923be21163b, rgb(133, 138, 142))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={hi9P_amFC} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-4ISQ7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4ISQ7 .framer-yk598g { display: block; }", ".framer-4ISQ7 .framer-q2bry4 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 2px 4px 2px 4px; position: relative; width: min-content; will-change: transform; }", ".framer-4ISQ7 .framer-163a0ji { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-4ISQ7 .framer-q2bry4 { gap: 0px; } .framer-4ISQ7 .framer-q2bry4 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-4ISQ7 .framer-q2bry4 > :first-child { margin-left: 0px; } .framer-4ISQ7 .framer-q2bry4 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"hi9P_amFC":"title"}
 */
const FrameraXEgrGDj_: React.ComponentType<Props> = withCSS(Component, css, "framer-4ISQ7") as typeof Component;
export default FrameraXEgrGDj_;

FrameraXEgrGDj_.displayName = "Keyboard Shortcut";

FrameraXEgrGDj_.defaultProps = {height: 16, width: 14};

addPropertyControls(FrameraXEgrGDj_, {hi9P_amFC: {defaultValue: "S", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FrameraXEgrGDj_, [{family: "Beausite Classic Web Clear", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/aXEgrGDj_:default", url: "assets/SHgmqAJAgBFmJPj6E4x39i6R5E.woff2"}, url: new URL("assets/SHgmqAJAgBFmJPj6E4x39i6R5E.woff2", import.meta.url).href}])